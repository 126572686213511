.App {
  line-height: 1.5;
}
@font-face {
  font-family: "M + 1c";
  font-style: normal;
  src: url("./assets/fonts/M+1/MPLUS1-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "M + 1c";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/M+1/static/MPLUS1-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Futura";
  font-style: normal;
  src: url("./assets/fonts/Futura/Futura.ttf") format("truetype");
}
@font-face {
  font-family: "Futura";
  font-style: italic;
  font-weight: normal;
  src: url("./assets/fonts/Futura/Futura-Medium-Italic-font.ttf") format("truetype");
}
@font-face {
  font-family: "Futura";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Futura/Futura-Bold-font.ttf") format("truetype");
}
@font-face {
  font-family: "Futura";
  font-style: italic;
  font-weight: bold;
  src: url("./assets/fonts/Futura/Futura-Bold-Italic-font.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSansJPWebFont";
  src: url("./assets/fonts/NotoSansJP/NotoSansJP-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "NotoSansJPWebFont";
  src: url("./assets/fonts/NotoSansJP/NotoSansCJKjp-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "RobotoWebFont";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "RobotoWebFont";
  src: url("./assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "RobotoWebFont";
  src: url("./assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: "OswaldWebFont";
  src: url("./assets/fonts/Oswald/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OswaldWebFontBold";
  src: url("./assets/fonts/Oswald/Oswald-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Alverata";
  src: url("assets/fonts/Alverata/AlverataBl-Italic.otf") format("truetype");
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "Alverata";
  src: url("assets/fonts/Alverata/AlverataBl-Italic.otf") format("truetype");
  font-style: italic;
  font-weight: 900;
}
